<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<style>
/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0px;
  /* 设置滚动条宽度 */
}

.no-padding {
  padding: 0 !important;
}

.v-application__wrap {
  background-color: rgb(43, 43, 43);
}
</style>
