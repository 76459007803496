import { createRouter, createWebHistory } from "vue-router";

import MyMain from "@/components/MyMain.vue";
import EpicWin from "@/components/MyGameList.vue";
import BigPot from "@/components/MyGameList.vue";

const routes = [
  { path: "/", component: MyMain },
  { path: "/EpicWin", component: EpicWin },
  { path: "/BigPot", component: BigPot },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
